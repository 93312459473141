@import '~@andes/typography/index';
@import '~@andes/typography/base';

body {
  background-color: $andes-bg-secondary;
}

.error {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: center;
  text-align: center;
  padding: $andes-spacing-28;

  &__title {
    margin-bottom: $andes-spacing-24 !important;
  }

  &__subtitle {
    color: $andes-text-color-secondary;
  }
}